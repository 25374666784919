(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name login.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('login')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl($scope, $timeout, $location, $mdDialog, Login) {

    $scope.showLoginForm = true;
    $scope.showCodeForm = false;

    $scope.errorMessage = undefined;
    $scope.errorTitle = undefined;
    $scope.phoneError = false;

    $scope.sendCode = function() {

      $scope.errorMessage = undefined;
      $scope.errorTitle = undefined;
      $scope.phoneError = false;

      var cleanPhone = $scope.phone.replace(/\D/g, '');
      if (cleanPhone.length != 10) {
        $scope.phoneError = true;
        $scope.errorTitle = undefined;
        $scope.errorMessage = "מספר הטלפון לא תקין";
        $mdDialog.show({
          templateUrl: 'login/errorMessage.html',
          scope: $scope,
          preserveScope: true
        });
        return;
      }

      showLoader();
      Login.createUserAndGetCode(cleanPhone).then(function(response) {
        hideLoader();
        if (response.data.success) {
          $scope.setItemInLocalStorage('codeSentDate', new Date());
          $scope.setItemInLocalStorage('phone', cleanPhone);
          $scope.goToCodeEnter();
        } else {
          // $scope.errorMessage = "הודעה כבר נשלחה למספר זה, ניתן לשלוח שוב בעוד "
          // $scope.errorMessage += response.data.timeRemaining;
          // $scope.errorMessage += " דקות";
          $scope.errorTitle = undefined;
          $scope.errorMessage = "הודעה נשלחה למספר זה";
          $mdDialog.show({
            templateUrl: 'login/errorMessage.html',
            scope: $scope,
            preserveScope: true
          });
        }
      })
    }


    $scope.verifyCode = function() {
      $scope.errorMessage = undefined;
      var cleanPhone = $scope.phone.replace(/\D/g, '');
      Login.verifyLoginCode(cleanPhone, $scope.code).then(function(response) {
        if (response.data.success) {
          $scope.setItemInLocalStorage("loginToken", response.data.token);
          $scope.setItemInLocalStorage('userDetails', response.data.user);
          $location.path("menu");
        } else {
          $scope.code = "";
          $scope.errorTitle = "סיסמא שגויה"
          $scope.errorMessage = response.data.message;
          $mdDialog.show({
            templateUrl: 'login/errorMessage.html',
            scope: $scope,
            preserveScope: true
          });
        }
      })
    }

    $scope.goToCodeEnter = function() {
      $scope.showLoginForm = false;

      $timeout(function() {
        $scope.showCodeForm = true;
      }, 800);
    }

    $scope.backToPhoneEnter = function() {
      $scope.showCodeForm = false;
      $timeout(function() {
        $scope.showLoginForm = true;
      }, 1000);
    }

    $scope.checkIfCodeSent = function() {
      var codeExpirationMinutes = 60;
      var dateStr = $scope.getItemFromLocalStorage('codeSentDate');
      if (dateStr != undefined) {
        var date = new Date(dateStr);
        var now = new Date();
        var diffDays = Math.round((now - date) / 86400000); // days
        var diffHrs = Math.round(((now - date) % 86400000) / 3600000);
        var diffMinutes = Math.round((((now - date) % 86400000) % 3600000) / 60000);
        if (diffMinutes < codeExpirationMinutes && diffHrs == 0 && diffDays == 0) {
          $scope.phone = $scope.getItemFromLocalStorage('phone');
          $scope.goToCodeEnter();
        }
      }
    }

    $scope.hideDialog = function() {
      if ($scope.showCodeForm == false && !$scope.phoneError) {
        $scope.goToCodeEnter();
      }
      $mdDialog.hide();
    }


    $scope.checkIfCodeSent();
  }
}());
